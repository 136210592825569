import React, { forwardRef, useEffect, useRef, useImperativeHandle, useState } from 'react';
import intlTelInput from 'intl-tel-input';
import { inputDigitsOnly } from '../../helper';

interface TelInputProps {
  name?: string;
  salla: any;
  phoneEntered: (phone: { number: string; country_code: string }) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  autofocus?: boolean;
  isRegistration?: boolean
}

export interface TelInputRef {
  getValues: () => Promise<{ [key: string]: string }>;
  isValid: () => Promise<boolean>;
}

const preferredCountries = ['sa', 'ae', 'kw', 'bh', 'qa', 'iq', 'om', 'ye', 'eg', 'jo', 'ps', 'sd', 'lb', 'dz', 'tn', 'ma', 'ly','ua'];

const TelInput: React.ForwardRefRenderFunction<TelInputRef, TelInputProps> = ({ autofocus = true, isRegistration = false, ...props }, ref) => {
  const [phone, setPhone] = useState<string>('');
  const [countryCode, setCountryCode] = useState(props.salla.config.get('user.country_code', 'SA') || 'SA');
  const phoneInputRef = useRef<HTMLInputElement>(null);
  const telInputWrapper = useRef<HTMLDivElement>(null);
  const countryCodeInputRef = useRef<HTMLInputElement>(null);
  const errorMsgRef = useRef<HTMLSpanElement>(null);
  const itiRef = useRef<any>(null);
  const dropdownContainerRef = useRef<HTMLDivElement>(null);

  const mobileLabel = props.salla.lang.get('common.elements.mobile');
  const countryCodeLabel = props.salla.lang.get('common.elements.country_code');
  const invalidNumber = props.salla.lang.get('common.errors.invalid_value', { attribute: mobileLabel });
  const invalidCountryCode = props.salla.lang.get('common.errors.invalid_value', { attribute: countryCodeLabel });
  const tooShort = props.salla.lang.get('common.errors.too_short', { attribute: mobileLabel });
  const tooLong = props.salla.lang.get('common.errors.too_long', { attribute: mobileLabel });
  const mobileRequired = props.salla.lang.get('common.errors.field_required', { attribute: mobileLabel });
  const errorMap = [invalidNumber, invalidCountryCode, tooShort, tooLong, invalidNumber];
  const authCountries = props.salla.config.get('store.settings.auth.countries') || ['all'];
  const showDropdown = authCountries?.length === 1 && authCountries?.[0] !== 'all' && !isRegistration ? false : true;

  useEffect(() => {
    initTelInput();
    return () => {
      itiRef.current?.destroy();
    };
    // eslint-disable-next-line
  }, []);

  const initTelInput = () => {
    if (!phoneInputRef.current) return;

    const telInputOptions: any = {
      initialCountry: countryCode.toLowerCase(),
      allowDropdown: showDropdown,
      preferredCountries: authCountries?.[0] !== 'all' && !isRegistration ? Array.from(new Set([...preferredCountries, ...authCountries])) : preferredCountries,
      excludeCountries: ['sy', 'ir', 'cu', 'kp'],
      formatOnDisplay: false,
      separateDialCode: true,
      autoPlaceholder: 'aggressive',
      dropdownContainer: dropdownContainerRef.current || undefined,
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/21.2.7/js/utils.min.js',
    };
		
    if (authCountries?.[0] !== 'all' && !isRegistration) {
      telInputOptions.onlyCountries = authCountries;
    }

    itiRef.current = intlTelInput(phoneInputRef.current, telInputOptions);

    phoneInputRef.current.addEventListener('countrychange', handleCountryChange);

    return () => {
      phoneInputRef.current?.removeEventListener(
        'countrychange',
        handleCountryChange
      );
    };
  };

  const handleCountryChange = () => {
    const data = itiRef.current.getSelectedCountryData();
    const value = data.iso2.toUpperCase();
    if (countryCodeInputRef.current) countryCodeInputRef.current.value = value;
    setCountryCode(value);
    props.phoneEntered && props.phoneEntered({ number: phone, country_code: value });
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (props.onKeyDown) {
      props.onKeyDown(e);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setPhone(inputValue);
    reset();
    props.phoneEntered({ number: inputValue, country_code: countryCode });
  };

  const validateInput = () => {
    reset();
    if (itiRef.current && itiRef.current.isValidNumber()) {
      return true;
    }

    if (!phone.trim()) {
      phoneInputRef.current?.classList.add('s-has-error');
      errorMsgRef.current!.innerText = mobileRequired || 'The mobile is required';
      return false;
    }

    phoneInputRef.current?.classList.add('s-has-error');
    const errorCode = itiRef.current.getValidationError();
    errorMsgRef.current!.innerText = errorMap?.[errorCode] || '';

    console.info(`Phone number (${countryCode} - ${phone}) is not valid, error code ${errorCode} .`);
    return false;
  };

  const reset = () => {
    phoneInputRef.current?.classList.remove('s-has-error');
    if (errorMsgRef.current) {
      errorMsgRef.current.innerHTML = '';
    }
  };

  useImperativeHandle(ref, () => ({
    getValues: async () => {
      return {
        [props.name || '']: phone,
        countryCode,
        countryKey: (telInputWrapper.current && (telInputWrapper.current.querySelector('.iti__selected-dial-code') as HTMLElement)?.innerText) || ''
      };
    },
    isValid: async () => {
      return validateInput();
    },
  }));

  return (
    <div className={`s-tel-input ${!showDropdown ? 'hide-dropdown' : ''}`} ref={telInputWrapper}>
      <input
        type="tel"
        name={props.name}
        value={phone}
        onChange={handleInputChange}
        onKeyDown={handleOnKeyDown}
        onInput={(e) => inputDigitsOnly(e.currentTarget)}
        ref={phoneInputRef}
        enterKeyHint="next"
        autoComplete="tel"
        className="s-tel-input-control tel-input s-ltr"
      />
      <span className="s-tel-input-error-msg" ref={errorMsgRef} />
      <input type="hidden" name="country_code" value={countryCode} ref={countryCodeInputRef} className="country_code" />
      <div ref={dropdownContainerRef} className="dropdown-container" />
    </div>
  );
};

export default forwardRef(TelInput);
